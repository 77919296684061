function paramifyParameters(parameters) {
  return Object.keys(parameters || []).map((key) => `${ key }=${ parameters[key] }`).join('&');
}

export default function apiRequest({ endpoint, method = 'GET', body, parameters }) {
  return fetch(`${ process.env.REACT_APP_API_BASE_URL }/${ endpoint }?${ paramifyParameters(parameters) }`, {
    method,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
}