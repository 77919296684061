import { useEffect, useState } from "react";
import apiRequest from "./utilities/api";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import CodeError from "./utilities/error";

export default function Home() {
  const [ticket, setTicket] = useState(null);

  const [error, setError] = useState(false);

  useEffect(() => {
    getTicket();
  }, []);

  async function getTicket() {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const ticketId = queryParams.get('ticket_id');

      if (!ticketId) {
        throw new CodeError('No ticket ID provided', 404);
      }

      const ticket = (await apiRequest({ endpoint: 'v3/getPublicTicket', parameters: { ticket_id: ticketId } }))?.data?.[0];

      if (!ticket) {
        throw new CodeError('Failed to load ticket.', 404);
      }

      document.title = `${ ticket?.sender_business_name } - ${ ticket?.type_of } #${ ticket?.id }`;

      setTicket(ticket);

    } catch (err) {
      setError(err.message);
    }
  }

  function renderTicketHtml () {
    const { raw_html } = ticket;

    return <div id="ticket-container" dangerouslySetInnerHTML={{ __html: raw_html }} />
  }

  function renderMenu() {
    const { pdf_s3_url } = ticket;
    return (
      <div id="menu">
        <a href={pdf_s3_url} target='_blank' rel="noreferrer"><button style={{ cursor: 'pointer' }}>Download as PDF</button></a>
      </div>
    )
  }

  if (error) {
    return (
      <div id="container">
        <h1>{error}</h1>
      </div>
    );
  }

  if (!ticket) {
    return (
      <div id="container">
        <div style={{ margin: '0 auto' }} className="loader"></div>
      </div>
    );
  }

  return (
    <div id="container">
      { renderMenu() }
      { renderTicketHtml() }

    </div>
  )
}